import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../App";
import { CompactContext } from "../../App";
import CheckboxBox from "./CheckboxBox2";
import ControlledCheckbox from "./ControlledCheckbox";
import { Typography } from "@mui/material";

export default function LeaseCheckbox({ register }) {
  const desktop = useContext(DesktopContext);
  const compact = useContext(CompactContext);

  LeaseCheckbox.propTypes = {
    register: PropTypes.func.isRequired,
  };

  var neighbourdispute = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"neighbourdispute"}
        checkboxID={"neighbourdispute"}
      />
    </>
  );

  var boundarydispute = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"boundarydispute"}
        checkboxID={"boundarydispute"}
      />
    </>
  );

  var rightofway = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"rightofway"}
        checkboxID={"rightofway"}
      />
    </>
  );

  var adversepossession = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"adversepossession"}
        checkboxID={"adversepossession"}
      />
    </>
  );

  var restrictivecovenant = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"restrictivecovenant"}
        checkboxID={"restrictivecovenant"}
      />
    </>
  );

  var newbuilddispute = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"newbuilddispute"}
        checkboxID={"newbuilddispute"}
      />
    </>
  );

  var claims = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"claims"}
        checkboxID={"claims"}
      />
    </>
  );

  return (
    <>
      {compact && (
        <Typography
          sx={{
            color: "#4F145B !important",
            fontWeight: "500 !important",
            fontSize: desktop ? "1.1rem !important" : "1rem !important",
            mt: desktop ? "1rem !important" : "1rem !important",
            ml: desktop ? "1 !important" : "0 !important",
            mb: desktop ? "0.5rem !important" : "0.5rem !important",
          }}
        >
          What Do You Need Help With?
        </Typography>
      )}

      <CheckboxBox
        checkboxes={neighbourdispute}
        label={"Neighbour dispute"}
        style={{lineHeight: "1rem"}}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={boundarydispute}
        label={"A boundary dispute"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={rightofway}
        label={"Rights of way/right of access"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={adversepossession}
        label={"Adverse possession"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={restrictivecovenant}
        label={"Restrictive covenant issues"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={newbuilddispute}
        label={"New build dispute"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={claims}
        label={"Dispute with a builder, surveyor, etc."}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
    </>
  );
}
