import React from "react";
import PropTypes from "prop-types";
import LeaseCheckboxes from "../../../../../components/checkbox/PropertyDisputesCheckboxes";

// PropTypes
WhichServices.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default function WhichServices({ register }) {
 
  return (
      <LeaseCheckboxes register={register} />
  );
}
